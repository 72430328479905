import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import ComposeProviders from 'components/ui/ComposeProviders'
import { CustomerProvider } from 'contexts/customerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomizedWalletUpdateTemplate from 'templates/customizedWalletUpdate/updateForm'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const CustomizedWalletUpdate = () => {
  const navigate = useNavigate()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const { user, isLoadingProfile, isSuccessProfile, isBackoffice4c } =
    useProfile()
  const ignoreAuthOkta = getStorageItem('ignoreAuthOkta')

  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkPermissionsAndAuth = async () => {
      setIsChecking(true)

      if (ignoreAuthOkta === 'true') {
        setIsChecking(false)
        return
      }

      try {
        if (!isLoadingProfile && isSuccessProfile) {
          const hasPermission = isBackoffice4c

          if (user && !authState?.isAuthenticated && !isDevSide()) {
            await oktaAuth.signInWithRedirect()
          }
          if (user && !hasPermission) navigate('/not-allowed')
        } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
          await oktaAuth.signInWithRedirect()
        }
      } finally {
        setIsChecking(false)
      }
    }

    checkPermissionsAndAuth()
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isBackoffice4c,
    navigate,
    user,
    oktaAuth,
    ignoreAuthOkta,
    getStorageItem
  ])

  if (isLoadingProfile || isChecking) return <Loading />

  const providers = [
    { Provider: ProfileProvider },
    { Provider: CustomerProvider }
  ]

  return (
    <ComposeProviders providers={providers}>
      <CustomizedWalletUpdateTemplate />
    </ComposeProviders>
  )
}

export default CustomizedWalletUpdate
