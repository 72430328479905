import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import ComposeProviders from 'components/ui/ComposeProviders'
import { AccountsHistoryProvider } from 'contexts/accountHistoryContext'
import { ConsumerProvider } from 'contexts/consumerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConsumerTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Consumer = () => {
  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()
  const ignoreAuthOkta = getStorageItem('ignoreAuthOkta')
  const {
    user,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isManageTransactions,
    isLoadingProfile,
    isSuccessProfile,
    isPayments,
    isSupervisorB2b
  } = useProfile()

  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkPermissionsAndAuth = async () => {
      setIsChecking(true)

      if (ignoreAuthOkta === 'true') {
        setIsChecking(false)
        return
      }

      try {
        if (!isLoadingProfile && isSuccessProfile) {
          const hasPermission =
            isAttendant ||
            isFraudAndPrevention ||
            isBackoffice4c ||
            isOperation3c ||
            isAttendant3c ||
            isAttendantB2M ||
            isManageTransactions ||
            isPayments ||
            isSupervisorB2b

          if (user && !authState?.isAuthenticated && !isDevSide()) {
            await oktaAuth.signInWithRedirect()
          }
          if (user && !hasPermission) navigate('/not-allowed')
        } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
          await oktaAuth.signInWithRedirect()
        }
      } finally {
        setIsChecking(false)
      }
    }

    checkPermissionsAndAuth()
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isManageTransactions,
    isSupervisorB2b,
    navigate,
    user,
    authState,
    ignoreAuthOkta,
    oktaAuth,
    getStorageItem
  ])

  if (isLoadingProfile || isChecking) return <Loading />

  const providers = [
    { Provider: ProfileProvider },
    { Provider: ConsumerProvider },
    { Provider: AccountsHistoryProvider }
  ]

  return (
    <ComposeProviders providers={providers}>
      <ConsumerTemplate />
    </ComposeProviders>
  )
}

export default Consumer
