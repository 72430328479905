import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { objectIsEmpty } from 'utils/objectUtils'

import { Loading } from '.'

const LoginCallback = () => {
  const navigate = useNavigate()
  const { oktaAuth } = useOktaAuth()
  const [isProcessing, setIsProcessing] = useState(true)

  useEffect(() => {
    async function handleAuthentication() {
      try {
        await oktaAuth.handleLoginRedirect()
        const tokens = oktaAuth.tokenManager.getTokensSync()

        window.localStorage.setItem(
          'okta-token-storage',
          JSON.stringify(tokens)
        )

        if (!objectIsEmpty(window.localStorage.getItem('okta-token-storage'))) {
          navigate('/')
        } else {
          oktaAuth.signInWithRedirect()
        }
      } catch (error) {
        console.error('Erro ao processar o retorno de chamada de login:', error)
        oktaAuth.signInWithRedirect()
      } finally {
        oktaAuth.transactionManager.clear()
        setIsProcessing(false)
      }
    }

    handleAuthentication()
  }, [oktaAuth])

  if (isProcessing) return <Loading />

  return <div>Processando retorno de chamada de login...</div>
}

export default LoginCallback
