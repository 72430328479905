import { useMutateGetByCPFWithFilters } from 'hooks/queries/consumerQueries'
import { BlockReason } from 'models/BlockReason'
import { Consumer } from 'models/Consumer'
import { ConsumerAccount } from 'models/ConsumerAccount'
import { Pagination } from 'models/Pagination'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { GetByCpfFilters } from 'services/consumerService'

export type ContextType = {
  consumer?: Consumer
  consumerAccounts: ConsumerAccount[]
  consumerAccountsPagination?: Pagination

  selectedConsumer: Consumer | undefined
  cpf: string

  blockReasons: BlockReason[]
  setBlockReasons: (data: BlockReason[]) => void

  isLoading: boolean
  isError: boolean
  isSuccess: boolean

  isLoadingWithFilters: boolean
  isSuccessWithFilters: boolean

  isShowAllAccounts: boolean
  isShowAllTransactions: boolean

  currentFilters: GetByCpfFilters

  setCPF: (value: string) => void
  selectConsumer: (consumer: Consumer) => void
  getConsumersByFilter: (filters: GetByCpfFilters) => void
  setIsShowAllAccounts: (value: boolean) => void
  setIsShowAllTransactions: (value: boolean) => void

  isErrorBlockReasons: boolean
  setIsErrorBlockReasons: (value: boolean) => void

  blockReasonsForPreBlocked: BlockReason[]

  checkboxChecked: boolean
  setCheckboxChecked: (value: any) => void

  isFirstRequest: boolean
  setIsFirstRequest: (value: boolean) => void
}

export const Context = createContext({} as ContextType)

type ConsumerProviderProps = {
  children: ReactNode
}

export const ConsumerProvider = ({ children }: ConsumerProviderProps) => {
  const [consumer, setConsumer] = useState<Consumer>()
  const [consumerAccounts, setConsumerAccounts] = useState<ConsumerAccount[]>(
    []
  )
  const [consumerAccountsPagination, setConsumerAccountsPagination] =
    useState<Pagination>()

  const [blockReasons, setBlockReasons] = useState<BlockReason[]>([])
  const [isErrorBlockReasons, setIsErrorBlockReasons] = useState(false)

  const [isShowAllTransactions, setIsShowAllTransactions] = useState(true)
  const [isShowAllAccounts, setIsShowAllAccounts] = useState(false)

  const [cpf, setCPF] = useState('')
  const [selectedConsumer, setSelectedConsumer] = useState<Consumer>()

  const [isFirstRequest, setIsFirstRequest] = useState(false)
  const [hasFetchedCachedCPF, setHasFetchedCachedCPF] = useState(false)

  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(() => {
    const storedCheckbox = localStorage.getItem('checkboxChecked')
    return storedCheckbox ? JSON.parse(storedCheckbox) : false
  })

  const cachedCPF = window.localStorage.getItem('cachedCPF')

  const [currentFilters, setCurrentFilters] = useState<GetByCpfFilters>({})

  const mutateGetWithFilters = useMutateGetByCPFWithFilters()
  const {
    isLoading: isLoadingWithFilters,
    isSuccess: isSuccessWithFilters,
    data: queryConsumerByFilters,
    isError: isErrorWithFilters
  } = mutateGetWithFilters

  const getConsumersByFilter = async (filters: GetByCpfFilters) => {
    if (!filters.cpf) return

    setCurrentFilters({ ...filters })
    return await mutateGetWithFilters.mutateAsync({
      cpf: filters.cpf!,
      filters
    })
  }

  const selectConsumer = (consumer: Consumer) => setSelectedConsumer(consumer)

  useEffect(() => {
    if (cachedCPF && !hasFetchedCachedCPF) {
      setCPF(cachedCPF)
      setHasFetchedCachedCPF(true)
      setIsFirstRequest(true)
      getConsumersByFilter({
        cpf: cachedCPF,
        page: '0',
        status: checkboxChecked ? undefined : 'A'
      })
    }
  }, [cachedCPF, hasFetchedCachedCPF, checkboxChecked])

  useEffect(() => {
    localStorage.setItem('checkboxChecked', JSON.stringify(checkboxChecked))
  }, [checkboxChecked])

  useEffect(() => {
    if (isErrorWithFilters) {
      setConsumerAccounts([])
      setConsumerAccountsPagination(undefined)
    }
  }, [isErrorWithFilters])

  useEffect(() => {
    if (queryConsumerByFilters) {
      const accountsResults = queryConsumerByFilters.accountsPages

      setConsumer(queryConsumerByFilters.consumer)
      setConsumerAccounts(accountsResults.accounts)
      setConsumerAccountsPagination({
        currentPage: accountsResults.page?.currentPage,
        totalPages: accountsResults.page?.totalPages,
        totalResults: accountsResults.page?.totalItems,
        lastPage: !!accountsResults.page?.lastPage
      })
    }
  }, [queryConsumerByFilters])

  const blockReasonsForPreBlocked: BlockReason[] = [
    { id: 1, code: '026', description: 'DANIFICADO', type: 'C' },
    { id: 2, code: '020', description: 'PERDA', type: 'C' },
    { id: 3, code: '021', description: 'ROUBO', type: 'C' }
  ]

  return (
    <Context.Provider
      value={{
        consumer,
        consumerAccounts,
        consumerAccountsPagination,
        selectedConsumer,
        isLoading: isLoadingWithFilters,
        isError: isErrorWithFilters,
        isSuccess: isSuccessWithFilters,
        blockReasons,
        isLoadingWithFilters,
        isSuccessWithFilters,
        isShowAllAccounts,
        isShowAllTransactions,
        currentFilters,
        cpf,
        selectConsumer,
        setCPF,
        getConsumersByFilter,
        setIsShowAllAccounts,
        setIsShowAllTransactions,
        setBlockReasons,
        isErrorBlockReasons,
        setIsErrorBlockReasons,
        blockReasonsForPreBlocked,
        setCheckboxChecked,
        checkboxChecked,
        isFirstRequest,
        setIsFirstRequest
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useConsumer = () => {
  const context = useContext(Context)

  return context
}
