import { Box, Flex, Text } from '@chakra-ui/react'
import { Loading } from 'components/ui'
import {
  Container,
  Layout,
  SearchInput,
  SubmitButton,
  Checkbox
} from 'components/ui'
import { useConsumer } from 'contexts/consumerContext'
import { useProfile } from 'contexts/profileContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { translate } from 'internationalization'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { ConsumersTable } from './consumersTable'

const SearchConsumerPluxee = () => {
  const { user } = useProfile()
  if (!user) return <Loading />

  const { removeStorageItem } = useLocalStorage()
  const removeCachedCpf = () => {
    removeStorageItem('cachedCPF')
    removeStorageItem('checkboxChecked')
  }

  return (
    <Layout isShowBackButton onPageBack={removeCachedCpf}>
      <Container>
        <Main removeCachedCpf={removeCachedCpf} />
      </Container>
    </Layout>
  )
}

export default SearchConsumerPluxee

const Main = ({ removeCachedCpf }: { removeCachedCpf: () => void }) => {
  const { alert } = useAlert()
  const minCpfCharacters = 11

  const [value, setValue] = useState('')
  const { setStorageItem } = useLocalStorage()
  const [isShowTable, setIsShowTable] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(true)

  const {
    cpf,
    setCPF,
    isLoading,
    isError,
    consumerAccounts,
    isSuccess,
    currentFilters,
    getConsumersByFilter,
    checkboxChecked,
    setCheckboxChecked,
    setIsFirstRequest,
    isFirstRequest
  } = useConsumer()

  const isEmptyTable =
    (!consumerAccounts.length && isSuccess && isFirstRequest) || isError

  const handleInputChange = (inputValue: string) => {
    removeCachedCpf()
    setValue(Mask.removeMaskCpf(inputValue))
  }

  const handleSearch = useCallback(() => {
    const cpfToSearch = value.slice(0, 11)

    if (value.length < minCpfCharacters) {
      return alertInvalidCPF()
    }

    searchConsumers(cpfToSearch)
  }, [value])

  const alertInvalidCPF = () => {
    alert({
      id: 'consumerToast',
      status: 'warning',
      title: translate('commons.invalidCPF')
    })
  }

  const searchConsumers = (cpf: string) => {
    setCPF(cpf)
    setStorageItem('cachedCPF', cpf)
    setIsFirstRequest(true)
    getConsumersByFilter({
      cpf,
      page: '0',
      status: checkboxChecked ? undefined : 'A'
    })
  }

  useEffect(() => {
    setValue(cpf)
  }, [cpf])

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedCpf)
    return () => {
      window.removeEventListener('beforeunload', removeCachedCpf)
    }
  }, [removeCachedCpf])

  useLayoutEffect(() => {
    validateCPFLength()
  }, [value])

  useLayoutEffect(() => {
    toggleTableVisibility()
  }, [consumerAccounts])

  useEffect(() => {
    handleCheckboxChange()
  }, [checkboxChecked])

  useEffect(() => {
    localStorage.setItem('checkboxChecked', JSON.stringify(checkboxChecked))
  }, [checkboxChecked])

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('cachedCPF')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    const storedCheckbox = localStorage.getItem('checkboxChecked')
    if (storedCheckbox !== null) {
      setCheckboxChecked(JSON.parse(storedCheckbox))
    }
  }, [])

  useEffect(() => {
    return () => {
      localStorage.setItem('checkboxChecked', JSON.stringify(checkboxChecked))
    }
  }, [checkboxChecked])

  const validateCPFLength = () => {
    const isValidCpf = value.length >= minCpfCharacters
    setIsDisabledButton(!isValidCpf)
  }

  const toggleTableVisibility = () => {
    setIsShowTable(consumerAccounts.length > 0)
  }

  const handleCheckboxChange = () => {
    if (checkboxChecked) {
      delete currentFilters.status

      getConsumersByFilter({
        ...currentFilters,
        page: '0'
      })
    } else {
      getConsumersByFilter({
        ...currentFilters,
        page: '0',
        status: 'A'
      })
    }
  }

  return (
    <Flex mb="3%" flexDir="column" bg="standard.white" w="100%" p="40px">
      <Text
        color="pluxee.text.primary"
        fontWeight="700"
        fontSize="md"
        mb="24px"
        letterSpacing="-0.8px"
      >
        Consultar colaborador
      </Text>

      <Flex gap="32px" alignItems="flex-end" mb="32px">
        <Box width="100%">
          <SearchInput
            size="1.5rem"
            customIcon="search"
            isError={isEmptyTable}
            onChange={handleInputChange}
            label="CPF do colaborador"
            placeholder="Informe o CPF do colaborador"
            value={Mask.CPF(value)}
          />

          {isEmptyTable && (
            <Text color="#B30000" fontSize="xxxs" fontWeight="600" mt="4px">
              Colaborador não encontrado, verifique e tente novamente.
            </Text>
          )}

          <Flex mt="24px">
            <Checkbox
              title="Mostrar contas inativas"
              checked={checkboxChecked}
              onChange={() =>
                setCheckboxChecked((prevState: boolean) => !prevState)
              }
            />
          </Flex>
        </Box>

        <SubmitButton
          decreaseMarginBottom
          onClick={handleSearch}
          isLoading={isLoading}
          isDisabled={isDisabledButton}
          customMarginBottom={!isEmptyTable ? '52.5px' : '74.5px'}
        />
      </Flex>

      {isShowTable && <ConsumersTable />}
    </Flex>
  )
}
