import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import ComposeProviders from 'components/ui/ComposeProviders'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect, useState } from 'react'
import { HomeTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Home = () => {
  const { user, isLoadingProfile, isSuccessProfile } = useProfile()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()

  const [isChecking, setIsChecking] = useState(true)
  const ignoreAuthOkta = getStorageItem('ignoreAuthOkta')

  useEffect(() => {
    const checkAuthState = async () => {
      setIsChecking(true)

      if (ignoreAuthOkta === 'true') {
        setIsChecking(false)
        return
      }

      try {
        if (!isLoadingProfile && isSuccessProfile) {
          if (user && !authState?.isAuthenticated && !isDevSide()) {
            await oktaAuth.signInWithRedirect()
          }
        } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
          await oktaAuth.signInWithRedirect()
        }
      } finally {
        setIsChecking(false)
      }
    }

    checkAuthState()
  }, [
    isLoadingProfile,
    isSuccessProfile,
    user,
    authState,
    ignoreAuthOkta,
    oktaAuth,
    getStorageItem
  ])

  if (isLoadingProfile || isChecking) return <Loading />

  const providers = [{ Provider: ProfileProvider }]

  return (
    <ComposeProviders providers={providers}>
      <HomeTemplate />
    </ComposeProviders>
  )
}

export default Home
