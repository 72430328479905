import { Button, Center, Text } from 'components/atoms'
import { StatusLabel, Table } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useConsumer } from 'contexts/consumerContext'
import { usePagination } from 'hooks/usePagination'
import type { ConsumerAccount } from 'models/ConsumerAccount'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router-dom'
import { limitString } from 'utils/stringUtils'

import styles from './consumersTable.module.css'

export const ConsumersTable = () => {
  const navigate = useNavigate()

  const {
    consumer,
    consumerAccounts,
    consumerAccountsPagination,
    isLoadingWithFilters
  } = useConsumer()

  const results = consumerAccounts.map(item => {
    return {
      account: item.account,
      customer: item.customer,
      consumer
    }
  })

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: consumerAccountsPagination?.currentPage ?? 0
    })

  const columns: CellProps[] = [
    {
      title: 'Nome',
      width: '20%',
      render: (data: Record<keyof ConsumerAccount, any>) => {
        const consumer = data.consumer

        const isGreaterThan40 = consumer.name?.length >= 40
        const formattedName = isGreaterThan40
          ? limitString(consumer.name, 40)
          : consumer.name

        return (
          <Text
            fontSize="xxs"
            fontWeight="500"
            color="pluxee.text.secondary"
            lineHeight="150%"
          >
            {formattedName}
          </Text>
        )
      }
    },
    {
      title: 'Cliente',
      render: (data: Record<keyof ConsumerAccount, any>) => {
        return (
          <Text fontSize="xxs" fontWeight="500" color="pluxee.text.secondary">
            {data.customer?.name}
          </Text>
        )
      }
    },

    {
      title: 'Cód. do cliente',
      render: (data: Record<keyof ConsumerAccount, any>) => (
        <Text fontSize="xxs" fontWeight="500" color="pluxee.text.primary">
          {data.customer?.id}
        </Text>
      )
    },
    {
      title: 'Nº da conta',
      render: (data: Record<keyof ConsumerAccount, any>) => {
        return (
          <Text fontSize="xxs" fontWeight="500" color="#0F2365">
            {data.account.id ?? '-'}
          </Text>
        )
      }
    },
    {
      title: 'Produto',
      render: (data: Record<keyof ConsumerAccount, any>) => {
        return (
          <Text fontSize="xxs" fontWeight="500" color="#0F2365">
            {data.customer?.product?.id} - {data.customer?.product?.name}
          </Text>
        )
      }
    },
    {
      title: 'Status',
      render: (data: Record<keyof ConsumerAccount, any>) => {
        return <StatusLabel statusType={data.account.status} />
      }
    },
    {
      title: ' ',
      width: '5%',
      render: () => (
        <Center
          as={Button}
          w="40px"
          h="40px"
          p="0"
          color="#1B51DC"
          borderRadius="0"
          background="transparent"
          className={styles.icon}
          _hover={{
            background: 'transparent'
          }}
          _focusVisible={{
            border: '3px solid #1B51DC',
            color: '#0F266D'
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.9341 16C26.8435 15.718 26.6959 15.3232 26.4827 14.8492C26.0351 13.8539 25.3439 12.6104 24.3954 11.4089C22.4965 9.00377 19.7801 7 16 7C12.2199 7 9.50346 9.00377 7.60465 11.4089C6.65612 12.6104 5.96493 13.8539 5.51729 14.8492C5.29402 15.3457 5.14267 15.7552 5.05339 16.0393C5.04189 16.0759 5.03201 16.1085 5.02355 16.1373C5.0373 16.2111 5.05907 16.313 5.09352 16.4446C5.18035 16.7761 5.32433 17.2064 5.53796 17.7041C5.96663 18.7026 6.63325 19.8737 7.55466 20.9794C9.37249 23.1608 12.0853 25 16 25C19.7801 25 22.4965 22.9962 24.3954 20.5911C25.3439 19.3896 26.0351 18.1461 26.4827 17.1508C26.6959 16.6768 26.8435 16.282 26.9341 16ZM16 28C26 28 30 17.2 30 16C30 14.8 26 4 16 4C6 4 2 14.8 2 16C2 18.4 6 28 16 28Z"
              fill="#1B51DC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19ZM16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
              fill="#1B51DC"
            />
          </svg>
        </Center>
      )
    }
  ]

  const handleOnClickRow = (props: ConsumerAccount) => {
    return navigate(`/consumer/details/${props.account.id}`, {
      state: props
    })
  }

  let minimumConsumersForPagination = 20
  if (consumerAccountsPagination?.lastPage) minimumConsumersForPagination = 1

  const isPaginated =
    consumerAccounts.length >= minimumConsumersForPagination &&
    Number(consumerAccountsPagination!.totalPages) > 1

  const isShowSkeleton = isLoadingWithFilters
  if (isShowSkeleton) return <Skeleton />

  return (
    <>
      <Table
        textType="initial"
        minWidth="100%"
        size="sm"
        columns={columns}
        list={results}
        isPaginated={isPaginated}
        currentPage={currentPage}
        totalPages={consumerAccountsPagination?.totalPages}
        totalResults={consumerAccountsPagination?.totalResults}
        onClickRow={handleOnClickRow}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
      />
    </>
  )
}

const Skeleton = () => {
  return (
    <ContentLoader viewBox="0 0 380 200">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="60" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="90" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="120" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="150" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="180" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="240" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="270" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="300" rx="3" ry="3" width="100%" height="20" />
    </ContentLoader>
  )
}
