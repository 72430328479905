import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react'

import { useProfile } from './profileContext'

type OktaContextValue = {
  login: () => Promise<void>
  logout: () => Promise<void>
  isLoadingLogout: boolean
}

const OktaContext = createContext<OktaContextValue>({
  login: async () => {},
  logout: async () => {},
  isLoadingLogout: false
})

const POST_REDIRECT_URI = process.env.REACT_APP_OKTA_LOGOUT_CALLBACK_URL

export const OktaProvider = ({ children }: { children: ReactNode }) => {
  const [isCheckingToken, setIsCheckingToken] = useState(true)

  const { oktaAuth, authState } = useOktaAuth()
  const { setIsLogged } = useProfile()
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)

  useEffect(() => {
    setIsCheckingToken(true)

    if (authState?.isAuthenticated) {
      setIsLogged(true)
      setIsCheckingToken(false)
    } else {
      setIsLogged(false)
      setIsCheckingToken(false)
    }
  }, [authState, setIsLogged])

  const login = async () => {
    try {
      await oktaAuth.signInWithRedirect()
    } catch (err) {
      console.log('Login error: ', err)
    }
  }

  const logout = async () => {
    setIsLoadingLogout(true)
    try {
      await oktaAuth.signOut({
        postLogoutRedirectUri: POST_REDIRECT_URI || ''
      })
    } finally {
      setIsLoadingLogout(false)
      setIsLogged(false)
    }
  }

  if (isCheckingToken) return <Loading />

  return (
    <OktaContext.Provider value={{ login, logout, isLoadingLogout }}>
      {children}
    </OktaContext.Provider>
  )
}

export const useOkta = () => useContext(OktaContext)
